.Container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 53px);
  overflow-y: auto;
}

.ActionsWrapper {
  cursor: pointer;
  border: none;
  background: transparent;
}

.ActionsWrapperDisabled {
  cursor: not-allowed;
  border: none;
  background: transparent;
}
